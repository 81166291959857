<div *ngIf="!isActiveUrl" class="alert-danger alert-offline"> 
    <i class="fas fa-ban"  aria-hidden='true'></i>&nbsp;
    <strong>
        {{content?.OfflineHeaderMessage || "offline!" }}
    </strong>
    <span>{{content?.OfflineMessage || 'this service is temporarily unavailable.  Please check back again later.'}}</span>
</div>

<img class="image-header" src="{{content?.HeaderImage}}">

<div class="container">
    <div class="row">
      <div class="col-md-12">
        <div style="text-align: center;">
        <div class="h2" id="virtualVisistsTitle">
            {{ content?.Title || "behavioral health virtual visits" }}
        </div>
            <p style="margin-top:30px;">
                {{ content?.SubTitle || "from talk therapy to prescriptions and medication management - diganose, treat and manage mental health issues." }}
            </p>
        </div>
      </div>
    </div>
</div>

<div class="container-fluid body-description-container">
  <div class="row">
    <div class="col-md-12">
      <div style="text-align: center;">
        <div *ngIf="isActiveUrl" class="submit-button-container submit-button-container-active">
          <button type="button" class="btn btn-primary submit-btn" 
          (click)="getRedirect()"
          > 
              {{ content?.SubmitLabel || "book an appointment" }}
          </button>
          <div style="font-style: italic;margin-top:10px;margin-bottom:5px;">
              {{ content?.ExternalSiteDisclaimerMessage || "you will be redirected to TeleMed2U website." }}
          </div>
        </div>
        <div *ngIf="!isActiveUrl" class="submit-button-container">
          <button type="button" [disabled]="true" class="btn btn-primary submit-btn"> 
              {{ content?.SubmitDisabledLabel || "book an appointment is unavailable" }}
          </button>
        </div>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col-md-5 sub-image-container">
        <img class="sub-image" src="{{content?.SubImage}}">
      </div>
      <div class="col-md-7" style="text-align: left;">

        <label class="control-label sub-heading">{{ content?.AboutTitle || "about behavioral health" }}</label> <br/>
        
        <div [innerHTML]="content?.BodyMessage"></div>
      </div>
    </div>
</div>