import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";

import { PrintMemberIdCardService } from "../shared/services/print-member-id-card.service";
import { PrintMemberIdCardSitecoreTemplate } from "../shared/print-member-id-card-sitecore-template";
import { MemberIdCard } from "../shared/member-id-card";
import { PhonePipe } from "../../core/shared/pipes/phone.pipe";
import { DateFormatPipe } from "../../core/shared/pipes/date-format.pipe";
import { Configuration } from "../../app.constants";
import { MemberProfile } from "../shared/member-profile";

import { jsPDF } from "jspdf";
// import { isDefined } from '@angular/compiler/src/util';
import { isNullOrUndefined } from "is-what";
import {
  LanguageService,
  INotifyChange,
} from "../../core/shared/services/language.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-print-member-id-card",
  providers: [PrintMemberIdCardService],
  templateUrl: "./print-member-id-card.component.html",
  styleUrls: ["./print-member-id-card.component.scss"],
})
export class PrintMemberIdCardComponent implements OnInit, OnDestroy {
  private memberIdCardService: PrintMemberIdCardService;

  message: string;
  memberIdCard: MemberIdCard = new MemberIdCard();
  memberIdCardContent: PrintMemberIdCardSitecoreTemplate =
    new PrintMemberIdCardSitecoreTemplate();
  phonePipe: PhonePipe = new PhonePipe();
  dateFormatPipe: DateFormatPipe = new DateFormatPipe();
  showKaiserMessage = false;
  healthNetwork: string;
  private subscription: Subscription;

  constructor(
    @Inject(ElementRef) elementRef: ElementRef,
    private printMemberIdCardService: PrintMemberIdCardService,
    private config: Configuration,
    private languageService: LanguageService
  ) {
    this.memberIdCardService = printMemberIdCardService;
  }

  ngOnInit() {
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe(
        (change: INotifyChange) => {
          if (change.propName === "languageCode") {
            this.onLoad();
          }
        }
      );
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLoad() {
    try {
      this.memberIdCardService.getmemberIdCardContent().subscribe(
        (response) => {
          this.memberIdCardContent = response;
          this.memberIdCardService.getmemberIdCard().subscribe(
            (res) => {
              this.memberIdCard = res;
              this.populateIdCard(this.memberIdCard.LineOfBusiness.LOBCode);
              this.showKaiserMessage = this.isKaiserAndMediCal();
            },
            (error) => {
              this.message = <any>error;
            }
          );
        },
        (error) => {
          this.message = <any>error;
        }
      );
    } catch (ex) {
      throw ex;
    }
  }

  mailCard() {
    location.href = "#/requestidcard";
  }

  printIdCard(sectionId: string) {
    let popupWindow;
    popupWindow = window.open("", "_blank", "top=50%,height=700,width=1200");
    const frontImageCanvas = document.getElementById(
      this.config.FrontImageHtmlElementId
    ) as HTMLCanvasElement;
    const backImageCanvas = document.getElementById(
      this.config.BackImageHtmlElementId
    ) as HTMLCanvasElement;
    const frontImgData = frontImageCanvas.toDataURL();
    const backImgData = backImageCanvas.toDataURL();
    var is_chrome = Boolean(popupWindow.chrome);
    popupWindow.document.write(
      `<body><table><tr><td><img src="` +
        frontImgData +
        `" width="500" height="350" '/></td><td><img src="` +
        backImgData +
        `"width="500" height="350" '/></td><tr></table>
    
           </body>`
    );
    if (is_chrome) {
      setTimeout(function () {
        // wait until all resources loaded
        popupWindow.document.close(); // necessary for IE >= 10
        window.focus(); // window.focus(); // necessary for IE >= 10
        popupWindow.print(); // change window to winPrint
        popupWindow.close();
        // change window to winPrint
      }, 100);
    } else {
      popupWindow.document.close();
      window.focus();
      popupWindow.print();
      popupWindow.close();
    }

    // Call member service for download request
    this.memberIdCardService.setPrintMemberIdCardRequest().subscribe();
  }

  download() {
    const frontImageCanvas = document.getElementById(
      this.config.FrontImageHtmlElementId
    ) as HTMLCanvasElement;
    const backImageCanvas = document.getElementById(
      this.config.BackImageHtmlElementId
    ) as HTMLCanvasElement;
    const frontImgData = frontImageCanvas.toDataURL();
    const backImgData = backImageCanvas.toDataURL();
    const doc = new jsPDF("l", "mm");

    doc.addImage(frontImgData, this.config.PNGImageType, 0, 30, 134, 80);
    doc.addImage(backImgData, this.config.PNGImageType, 150, 30, 134, 80);
    doc.save(this.config.PDFIdCardFileName);
    // Call member service for download request
    this.memberIdCardService.setDownloadMemberIdCardRequest().subscribe();
  }

  populateIdCard(lobCode: string) {
    const canvasFrontImage = document.getElementById(
      this.config.FrontImageHtmlElementId
    ) as HTMLCanvasElement;
    const canvasBackImage = document.getElementById(
      this.config.BackImageHtmlElementId
    ) as HTMLCanvasElement;
    const frontImageContext = canvasFrontImage.getContext(
      this.config.CanvasDimensions
    );
    const backImageContext = canvasBackImage.getContext(
      this.config.CanvasDimensions
    );
    const frontImage = new Image();
    const backImage = new Image();

    switch (lobCode) {
      case this.config.MediCalLOBCode: {
        this.populateMediCalIdCard(
          frontImage,
          backImage,
          frontImageContext,
          backImageContext
        );
        break;
      }
      case this.config.OneCareConnectLOBCode: {
        this.populateOCCIdCard(
          frontImage,
          backImage,
          frontImageContext,
          backImageContext
        );
        break;
      }
      case this.config.OneCareLOBCode: {
        this.populateOCIdCard(
          frontImage,
          backImage,
          frontImageContext,
          backImageContext
        );
        break;
      }
    }
  }

  populateOCCIdCard(
    frontImage,
    backImage,
    frontImageContext,
    backImageContext
  ) {
    const member = this.memberIdCard;
    const phonePipe = this.phonePipe;
    const config = this.config;
    const datePipe = this.dateFormatPipe;

    frontImage.onload = function () {
      frontImageContext.drawImage(frontImage, 0, 0, 550, 350);
      frontImageContext.font = config.OCCFont;
      frontImageContext.fillText(
        member.FirstName + config.SpaceString + member.LastName,
        155,
        189
      );
      frontImageContext.fillText(member.CIN, 130, 210);
      frontImageContext.fillText(config.OCHealthPlanNumber, 418, 210);
      frontImageContext.fillText(
        datePipe.transform(member.DateOfBirth, config.MomentDateFormat),
        142,
        229
      );
      frontImageContext.fillText(
        datePipe.transform(
          member.LineOfBusiness.LOBEffectiveDate.toString(),
          config.MomentDateFormat
        ),
        380,
        230
      );
      frontImageContext.fillText(member.ProviderName, 128, 255);
      frontImageContext.fillText(
        phonePipe.format(member.ProviderPhoneNumber, this),
        134,
        275
      );
      frontImageContext.fillText(
        member.HealthNetworkPlan.HealthNetworkPlanDisplayText,
        160,
        300
      );
      frontImageContext.fillText(
        phonePipe.format(member.HealthNetworkPlan.HealthNetworkPlanPhone, this),
        205,
        320
      );
      frontImageContext.fillText(member.RxBin, 400, 90);
      frontImageContext.fillText(member.RxPCN, 406, 107);
      frontImageContext.fillText(member.RxGroup, 420, 125);
      backImage.onload = function () {
        backImageContext.drawImage(backImage, 0, 0, 550, 350);
      };
    };

    frontImage.src = this.memberIdCardContent.OneCareConnectFrontImage;
    backImage.src = this.memberIdCardContent.OneCareConnectBackImage;
  }

  populateOCIdCard(frontImage, backImage, frontImageContext, backImageContext) {
    const member = this.memberIdCard;
    const phonePipe = this.phonePipe;
    const config = this.config;
    const datePipe = this.dateFormatPipe;

    frontImage.onload = function () {
      const row1 = 160;
      const row2 = 180;
      const row3 = 200;
      const row4 = 220;
      const row5 = 240;
      const row6 = 260;
      const row7 = 280;
      const row8 = 300;
      const row9 = 320;
      const col1 = 27;
      const col2 = 395;

      frontImageContext.drawImage(frontImage, 0, 0, 550, 350);
      //Drawing Label
      frontImageContext.font = config.OCBoldFont;
      frontImageContext.fillText(config.OCMemberNameLabel, col1, row1);
      frontImageContext.fillText(config.OCMemberIDLabel, col1, row2);
      frontImageContext.fillText(
        config.OCPersonalCareCoordinatorPhoneLabel,
        col1,
        row3
      );
      frontImageContext.fillText(config.OCHealthNetworkLabel, col1, row4);
      frontImageContext.fillText(config.OCHealthNetworkPhoneLabel, col1, row5);
      frontImageContext.fillText(config.OCPCPGroupLabel, col1, row6);
      frontImageContext.fillText(config.OCPCPPhoneLabel, col1, row7);
      frontImageContext.fillText(config.OCRxBINLabel, col2, row1);
      frontImageContext.fillText(config.OCRxPCNLabel, col2, row2);
      frontImageContext.fillText(config.OCRxGroupLabel, col2, row3);

      //Drawing Data
      frontImageContext.font = config.OCFont;
      frontImageContext.fillText(
        member.FirstName + config.SpaceString + member.LastName,
        150,
        row1
      );
      frontImageContext.fillText(member.CIN, 123, row2);
      frontImageContext.fillText(
        phonePipe.format(member.MemberServicesPhoneNumber, this),
        297,
        row3
      );
      frontImageContext.fillText(
        member.HealthNetworkPlan.HealthNetworkPlanDisplayText,
        157,
        row4
      );
      frontImageContext.fillText(
        phonePipe.format(member.HealthNetworkPlan.HealthNetworkPlanPhone, this),
        210,
        row5
      );

      frontImageContext.fillText(member.ProviderName, 175, row6);
      frontImageContext.fillText(
        phonePipe.format(member.ProviderPhoneNumber, this),
        127,
        row7
      );
      frontImageContext.fillText(config.OCCMSContractNumber, col1, row8);

      frontImageContext.fillText(member.RxBin, 460, row1);
      frontImageContext.fillText(member.RxPCN, 465, row2);
      frontImageContext.fillText(member.RxGroup, 478, row3);
    };

    backImage.onload = function () {
      backImageContext.drawImage(backImage, 0, 0, 550, 338);
    };

    frontImage.src = this.memberIdCardContent.OneCareFrontImage;
    backImage.src = this.memberIdCardContent.OneCareBackImage;
  }

  populateMediCalIdCard(
    frontImage,
    backImage,
    frontImageContext,
    backImageContext
  ) {
    const member = this.memberIdCard;
    const memberIdCardContent = this.memberIdCardContent;
    const phonePipe = this.phonePipe;
    const config = this.config;
    const datePipe = this.dateFormatPipe;
    const healthNetworkNameList: Array<string> = this.splitMyString(
      member.HealthNetworkPlan.HealthNetworkPlanDisplayText,
      35
    );
    const pcpNameList: Array<string> = this.splitMyString(
      member.ProviderName,
      35
    );
    const CCN =
      member.HealthNetworkPlan.HealthNetworkCode === config.CCNNetwork ||
      member.HealthNetworkPlan.HealthNetworkPlanCode === config.CODMEDA ||
      member.HealthNetworkPlan.HealthNetworkPlanCode === config.CODMEDA;

    frontImage.onload = function () {
      const mediCalIdLine1 = 125;
      const mediCalIdLine2 = 150;
      const mediCalIdLine3 = 175;
      const mediCalIdLine4 = 200;
      const mediCalIdLine5 = 210;
      const mediCalIdLine6 = 225;
      const mediCalIdLine7 = 245;
      const mediCalIdLine8 = 265;
      const mediCalIdLine9 = 285;
      const mediCalColumn1 = 35;
      const mediCalColumn2 = 365;
      const mediCalColumn3 = 390;
      const mediCalColumn4 = 402;
      const mediCalColumn5 = 435;

      frontImageContext.drawImage(frontImage, 0, 0, 550, 350);
      frontImageContext.font = config.MediCalBoldFont;

      // Display member name
      frontImageContext.fillText(
        member.FirstName + config.SpaceString + member.LastName,
        mediCalColumn1,
        mediCalIdLine1
      );

      // Display member Id label
      frontImageContext.fillText(
        memberIdCardContent.MediCalMemberIdLabel + config.SpaceString,
        mediCalColumn1,
        mediCalIdLine2
      );

      // Display member Id value
      frontImageContext.fillText(
        member.CIN,
        mediCalColumn1 + 90,
        mediCalIdLine2
      );

      // Display Eff Date label
      if (memberIdCardContent.MediCalEffectiveDateLabel) {
        frontImageContext.fillText(
          memberIdCardContent.MediCalEffectiveDateLabel,
          mediCalColumn2,
          mediCalIdLine2
        );
      } else {
        frontImageContext.fillText(
          config.MediCalEffectiveDateLabel,
          mediCalColumn2,
          mediCalIdLine2
        );
      }

      // Display Eff Date value
      frontImageContext.fillText(
        datePipe.transform(
          member.LineOfBusiness.LOBEffectiveDate.toString(),
          config.MomentDateFormat
        ),
        mediCalColumn5,
        mediCalIdLine2
      );

      // Display health network values
      if (healthNetworkNameList.length > 1) {
        frontImageContext.fillText(
          healthNetworkNameList[0],
          mediCalColumn1,
          mediCalIdLine5
        );
        frontImageContext.fillText(
          healthNetworkNameList[1],
          mediCalColumn1,
          mediCalIdLine6
        );
      } else {
        frontImageContext.fillText(
          healthNetworkNameList[0],
          mediCalColumn1,
          mediCalIdLine5
        );
      }

      // Display health network phone number
      frontImageContext.fillText(
        phonePipe.format(member.HealthNetworkPlan.HealthNetworkPlanPhone, this),
        mediCalColumn4,
        mediCalIdLine5
      );

      // Display DOB label
      if (memberIdCardContent.MediCalDOBLabel) {
        frontImageContext.fillText(
          memberIdCardContent.MediCalDOBLabel,
          mediCalColumn3,
          mediCalIdLine3
        );
      } else {
        frontImageContext.fillText(
          config.MediCalDOBLabel,
          mediCalColumn3,
          mediCalIdLine3
        );
      }

      // Display DOB value
      frontImageContext.font = config.MediCalBoldFont;
      frontImageContext.fillText(
        datePipe.transform(member.DateOfBirth, config.MomentDateFormat),
        mediCalColumn5,
        mediCalIdLine3
      );

      // add if statement for CODMEDA
      if (CCN === true) {
        if (
          !isNullOrUndefined(memberIdCardContent.MediCalPcpLabel) &&
          memberIdCardContent.MediCalPcpLabel
        ) {
          frontImageContext.fillText(
            memberIdCardContent.MediCalPcpLabel,
            mediCalColumn1,
            mediCalIdLine7
          );
        } else {
          frontImageContext.fillText(
            config.MediCalPcpLabel,
            mediCalColumn1,
            mediCalIdLine7
          );
        }
        //Commented out by US - 42494
        // if (
        //   !isNullOrUndefined(memberIdCardContent.MediCalRxGrpLabel) &&
        //   memberIdCardContent.MediCalRxGrpLabel
        // ) {
        //   frontImageContext.fillText(
        //     memberIdCardContent.MediCalRxGrpLabel,
        //     mediCalColumn2,
        //     mediCalIdLine9
        //   );
        // } else {
        //   frontImageContext.fillText(
        //     config.MediCalRxGrpLabel,
        //     mediCalColumn2,
        //     mediCalIdLine9
        //   );
        // }
        // frontImageContext.font = config.MediCalDataFont;
        // frontImageContext.fillText(
        //   member.RxPCN,
        //   mediCalColumn4,
        //   mediCalIdLine9
        // );

        // Display PCP names
        if (pcpNameList.length > 1) {
          frontImageContext.fillText(pcpNameList[0], 80, mediCalIdLine7);
          frontImageContext.fillText(pcpNameList[1], 80, mediCalIdLine8);
        } else {
          frontImageContext.fillText(pcpNameList[0], 80, mediCalIdLine7);
        }

        // Display PCP phone number
        frontImageContext.fillText(
          phonePipe.format(member.ProviderPhoneNumber, this),
          mediCalColumn4,
          mediCalIdLine7
        );
      }

      // frontImageContext.font = config.MediCalBoldFont;

      //Commented out by US - 42494
      // if (memberIdCardContent.MediCalRxServicesLabel) {
      //   frontImageContext.fillText(
      //     memberIdCardContent.MediCalRxServicesLabel,
      //     mediCalColumn1,
      //     mediCalIdLine7
      //   );
      // }
      // else {
      //   frontImageContext.fillText(
      //     config.MediCalRxServicesLabel,
      //     mediCalColumn1,
      //     mediCalIdLine7
      //   );
      // }

      // if (memberIdCardContent.MediCalVisionServicesLabel) {
      //   frontImageContext.fillText(
      //     memberIdCardContent.MediCalVisionServicesLabel,
      //     mediCalColumn1,
      //     mediCalIdLine8
      //   );
      // } else {
      //   frontImageContext.fillText(
      //     config.MediCalVisionServicesLabel,
      //     mediCalColumn1,
      //     mediCalIdLine8
      //   );
      // }

      //Commented out by US - 42494
      // if (memberIdCardContent.MediCalRxBinLabel) {
      //   frontImageContext.fillText(
      //     memberIdCardContent.MediCalRxBinLabel,
      //     mediCalColumn2,
      //     mediCalIdLine7
      //   );
      // }
      //else {
      //   frontImageContext.fillText(
      //     config.MediCalRxBinLabel,
      //     mediCalColumn2,
      //     mediCalIdLine7
      //   );
      // }

      //Commented out by US - 42494
      // if (memberIdCardContent.MediCalRXPCNLabel) {
      //   frontImageContext.fillText(
      //     memberIdCardContent.MediCalRXPCNLabel,
      //     mediCalColumn2,
      //     mediCalIdLine8
      //   );
      // }
      // else {
      //   frontImageContext.fillText(
      //     config.MediCalRxPCNLabel,
      //     mediCalColumn2,
      //     mediCalIdLine8
      //   );
      // }

      frontImageContext.font = config.MediCalSmallFont;
      if (memberIdCardContent.MediCalForMembersWhoMeetRequirementsLabel) {
        frontImageContext.fillText(
          memberIdCardContent.MediCalForMembersWhoMeetRequirementsLabel,
          mediCalColumn1,
          mediCalIdLine9
        );
      } else {
        frontImageContext.fillText(
          config.MediCalForMembersWhoMeetRequirementsLabel,
          mediCalColumn1,
          mediCalIdLine9
        );
      }

      //Commented out per US - 42494 by TN

      // frontImageContext.fillText(
      //   phonePipe.transform(member.RxServicesPhoneNumber, this),
      //   150,
      //   mediCalIdLine7
      // );
      // frontImageContext.fillText(
      //   phonePipe.format(member.VisionServicesPhoneNumber, this) +
      //     config.Asteriks,
      //   175,
      //   mediCalIdLine8
      // );

      //Commented out per US - 42494 by TN
      // frontImageContext.fillText(member.RxBin, mediCalColumn4, mediCalIdLine7);
      // frontImageContext.fillText(member.RxPCN, mediCalColumn4, mediCalIdLine8);
    };

    backImage.onload = function () {
      backImageContext.drawImage(backImage, 0, 0, 550, 350);
    };

    frontImage.src = this.memberIdCardContent.MediCalFrontImage;
    backImage.src = this.memberIdCardContent.MedicalBackImage;
  }

  isKaiserAndMediCal(): boolean {
    return (
      (this.memberIdCard?.HealthNetworkPlan?.HealthNetworkCode?.toLocaleUpperCase() ===
        this.config?.KaiserCodeRegular ||
        this.memberIdCard?.HealthNetworkPlan?.HealthNetworkCode?.toLocaleUpperCase() ===
          this.config?.KaiserCodeExpansion) &&
      this.memberIdCard?.LineOfBusiness?.LOBCode?.toUpperCase() ===
        this.config?.MediCalLOBCode?.toUpperCase()
    );
  }

  splitMyString(text: string, maxLength: number): string[] {
    if (!text || text === null || text === undefined) {
      return new Array();
    }
    if (maxLength >= text.length) {
      return new Array(text);
    }

    const splitText = text.split(" ");
    let list: Array<string> = new Array<string>();
    let line = "";

    for (const word of splitText) {
      if ((line + word).length > maxLength) {
        list.push(line);
        line = "";
      }

      if (line.length > 0) {
        line += " ";
      }

      line += word;
    }

    if (line.length > 0) {
      list.push(line);
    }

    return list;
  }
}
