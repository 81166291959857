<div class="container-fluid">
  <div class="centered-content form">
    <div class="row">
      <h1>{{memberIdCardContent.HeaderLabel||"print temporary id card"}}</h1>
    </div>
    <div class="row">
      <p>{{memberIdCardContent.InstructionsLabel|| "do you need a new caloptima id card? you can get a temporary copy," }}
        <a href="#/requestidcard"> {{memberIdCardContent.RequestIdCardByMailLink||"request an id card by mail"}}</a>
      </p>
    </div>
    <div class="row">
      <div class="info-main-content-section-top">
          <error-summary></error-summary>
        <div class="alert alert-info" *ngIf="showKaiserMessage">
          <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp; {{memberIdCardContent.KaiserDisclaimerMessage || "please be advised that you will need a Kaiser ID
          cards for services with Kaiser. please contact Kaiser Member Services at 1-800-464-4000 or TTY at 1-800-777-1370,
          if you require a replacement ID card"}}
        </div>
      </div>
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <canvas id="canvasFrontImage" width="550" height="350" class="img-fluid"></canvas>
            </div>
            <div class="col-md-6">
              <canvas id="canvasBackImage" width="550" height="350" class="img-fluid"></canvas>
            </div>
          </div>
      </div>
    </div>
 
    <div class="row ">
        <div class="col-md-12 text-center print-id-large-copy-margin">
          <button type="button" class="btn btn-primary btn-print-id-card" (click)="download()">
            {{memberIdCardContent.DownLoadButtonText || "download"
}}
          </button>
          <button type="button" class="btn btn-primary btn-print-id-card" (click)="printIdCard('sectionToPrint')">
              {{memberIdCardContent.PrintButtonText || "print"}}
            </button>
           
            <button type="button" class="btn btn-primary btn-print-id-card" (click)="mailCard()">
                {{memberIdCardContent.FooterRequestIdCardByMailLink||"mail"}}
            </button>
        </div>
      </div>
    <br>
  </div>
</div>
