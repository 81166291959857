<!-- Start Messages -->
<div class="msg-wrapper-header">
    {{messageTemplate?.MessagesHeader || 'messages'}}
</div>
<div class="msg-wrapper">
	<div class="msg-inner" *ngIf="!memberMessages || memberMessages.length === 0">
		{{messageTemplate?.EmptyMessages || 'check back here for updates from CalOptima.'}}</div>
	<!-- Messge Item -->
	<div class="msg-inner" *ngFor="let msg of memberMessages; let i = index">
		<!-- FLAG //-->
		<div [ngClass]="getType(msg.MessageModel.MessageType)"></div>
		<div class="msg-dot-container">
			<!-- UNREAD DOT //-->
      <i *ngIf="!msg.MessageStatus?.HasRead" class="fas fa-circle msg-dot-icon"></i>
		</div>
		<div class="msg-container" (click)="onMessageExpand(i)">
			<div class="msg-title-container">
				<div [ngClass]="msg.MessageStatus?.HasRead ? 'msg-title' : 'msg-title-unread'" [innerHTML] = "msg.MessageModel?.Title"></div>
				<div class="msg-date">
				<!-- DATE //-->
				{{msg.MessageStatus?.EffectiveDate | date}}
				</div>
			</div>
			<div [ngClass]="expandIndex == i ? 'msg-expanded': 'msg-collapsed'">
				<div class="msg-content" [innerHTML] = "msg.MessageModel?.MessageBody"></div>
				<div class="msg-url" [innerHTML] = "msg.MessageModel?.MessageFooter"></div>
			</div>
		</div>
		<div class="msg-controls-container">
			<div class="msg-chevron-container">
				<!-- CHEVRON //-->
				<i [ngClass]="expandIndex == i ? 'fas fa-chevron-up msg-chevron': 'fas fa-chevron-down msg-chevron'" (click)="onMessageExpand(i)"></i>
			</div>
			<div *ngIf="expandIndex == i" class="msg-trash-container">
				<div class="msg-trash-inner">
					<div class="msg-trash-aligner">
						<!-- TRASH //-->
						<i class="far fa-trash-alt msg-trash" (click)="onMessageDelete(i)"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Messge Item -->

</div>
<!-- End Messages -->
