
<div class="hdrow-md-12" *ngIf="currentHealthNetwork && !isKaiser">
    <h2>{{changePCPSitecoreTemplate?.PrimaryCareProviderLabel||'primary Care Provider'}}</h2>
    <div *ngIf="!loading">
        <div *ngIf="isReadOnly===false">
            <div *ngIf="showSearch && (selectedOption =='changePCP') && !isCurrentPCPInNetwork">
                <div *ngIf="(memberProfile.LOB.LOBCode == configuration.OneCareLOBCode || memberProfile.LOB.LOBCode == configuration.OneCareConnectLOBCode && memberProfile.HealthNetworkPlan.HealthNetworkCode!=currentHealthNetwork.HealthNetworkCode)" class="alert alert-info">
                    <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                    {{changePCPSitecoreTemplate?.OCAndOCCInstructionsLabelPCPNotAssociatedWithHN ||'you are required to select a new pcp. please search and select a new pcp below. '}}
                </div>
                <div *ngIf="selectedPCPOption && selectedPCPOption !='-1' && !isKaiserSelected" class="alert alert-info" id="msgChangePCPeffectiveDate" name="msgChangePCPeffectiveDate">
                    <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPEffectiveDateMessageText || 'your request to change your primary care provider will take eeffect on '}} {{showEffectiveDate|date: 'longDate'}}
                </div>
                <div class="alert alert-info" *ngIf="memberProfile.LOB.LOBCode == configuration.MediCalLOBCode && memberProfile.HealthNetworkPlan.HealthNetworkCode!=currentHealthNetwork.HealthNetworkCode">
                    <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                    {{changePCPSitecoreTemplate?.MediCalInstructionsLabelNotAssociatedWithHN||'you are required to select a new PCP. Please search and select a new PCP below. '}}
                </div>
            </div>
            <div *ngIf="canChangePCP && !isKaiser">
                <div *ngIf="selectedOption!='changePCP'">
                    <div class="alert alert-info" *ngIf="isAllowedToChangePCPWithoutHN  && memberProfile.HealthNetworkPlan.HealthNetworkCode==currentHealthNetwork.HealthNetworkCode">
                        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                        {{changePCPSitecoreTemplate?.OCAndOCCInstructionsLabel || 'you have the option to search for a new pcp. please indicate your selection below. '}}

                    </div>

                    <div *ngIf="isCurrentPCPInNetwork">
                        <div class="alert alert-info" *ngIf="memberProfile.LOB.LOBCode == configuration.MediCalLOBCode && memberProfile.HealthNetworkPlan.HealthNetworkCode!=currentHealthNetwork.HealthNetworkCode">
                            <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                            {{changePCPSitecoreTemplate?.PCPAssociatedWithHealthNetworkLabel || 'your current PCP is associated with your desired Health Network. if you would like to keep your current PCP, please indicated so below.'}}
                        </div>
                        <div *ngIf="(memberProfile.LOB.LOBCode == configuration.OneCareLOBCode || memberProfile.LOB.LOBCode == configuration.OneCareConnectLOBCode) && memberProfile.HealthNetworkPlan.HealthNetworkCode!=currentHealthNetwork.HealthNetworkCode" class="alert alert-info">
                            <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                            {{changePCPSitecoreTemplate?.OCAndOCCInstructionsLabelPCPAssociatedWithHN||  'you have the option to search for a new pcp or keep your current pcp.please indicate your selection below. '}}
                        </div>
                    </div>

                    <div *ngIf="showDropDown">
                        <div class="nohdrow-md-12">
                            <div class="row">
                                <div class="col-md-6 col-md-6 pcp-info-box">
                                    <select class="form-select" [(ngModel)]="selectedOption" id="ChangePCPOptions" name="ChangePCPOptions" (ngModelChange)="changeView($event);">
                                        <option selected value="-1">{{changePCPSitecoreTemplate?.Select}}</option>
                                        <option *ngIf="isCurrentPCPInNetwork" value="currentPCP">
                                            {{changePCPSitecoreTemplate?.KeepMyCurrentPCPOption||'keep my current PCP'}}
                                        </option>
                                        <option value="changePCP">{{changePCPSitecoreTemplate?.ChangeMyPCPOption||'let me Change my PCP'}}</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showSearch && (selectedOption =='changePCP')">
                    <div *ngIf="showSearch && canChangePCP && !isKaiser">
                        <div [ngClass]="{'hdrow-md-12':showSearchResults,'nohd-row-md-12':!showSearchResults} ">

                            <div>
                                <search-for-provider [collapseSearch]="collapseSearchPanel"
                                                     [memberProfile]="memberProfile"
                                                     [fontAwesomeArrow]="fontAwesomeArrow"
                                                     [showCollapsibleButton]="showCollapsibleButton"
                                                     (cancel)="onCancel()"
                                                     (searchForAProvider)="searchForAProviderEvent($event);"
                                                     (togglePanel)="expandSearchPanel()">
                                </search-for-provider>
                            </div>
                        </div>
                        <div *ngIf="providerList">
                            <div *ngIf="showSearchResults">
                                <div>
                                    <app-provider-search-results [memberProfile]="memberProfile"
                                                                 [providerInfo]="providerList"
                                                                 (providerDetails)="openProviderDetails($event);"
                                                                 (expandSearchPanel)="expandSearchPanel();"
                                                                 (selectedProvider)="onSelectedProviderChange($event)">
                                    </app-provider-search-results>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedOption === 'currentPCP' || selectedOption==='-1'">
            <app-pcpinfo *ngIf="pcpInfo.provider.ProviderId"
                         [properties]="pcpInfo.properties"
                         [provider]="pcpInfo.provider"
                         [healthNetworkCode]="memberProfile.HealthNetworkPlan.HealthNetworkPlanCode">
            </app-pcpinfo>
        </div>

        <div *ngIf="isReadOnly===false">
            <div class="alert alert-info" *ngIf="!canChangePCP && !isKaiser">
                <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                {{
                    changePCPSitecoreTemplate?.CanNotChangePCPLabel ||  'we are sorry, but you belong to a plan that does not allow you to change your PCP.'
                }}
            </div>
        </div>
    </div>
</div>



