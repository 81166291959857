<div class="centered-content container">
  <div class="row">
    <div class="col-md-12">
      <!-- <div class="col-md-12"> -->
      <div class="h1" id="updateMemberProfileTitle">
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileHeaderLabel ||
            "change personal information"
        }}
      </div>
      <p>
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileInstructionsLabel ||
            "you can change your personal information by completing the request form below. please note that changes to your personal information will take one (1) business day to update."
        }}
      </p>

      <div class="alert alert-info" *ngIf="!isBCCTPMember">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressDisclaimar
        }}
      </div>
      <div class="alert alert-info" *ngIf="showBCCTPPrimaryAddressMessage">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileMediCalBcctpDisclaimerText
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showAgedBlindDisabledPrimaryAddressMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileMediCalAidRestrictionText
        }}
      </div>
      <error-summary></error-summary>
      <div
        class="alert alert-info"
        *ngIf="showPrimaryAddressOneCareOutOfCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOneCareOutofAreaAddressChangeText ||
            "the address you entered is outside of onecare's covered service area. a member of our customer service team will contact you to complete your request."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showPrimaryAddressOneCareConnectOutOfCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOneCareConnectOutofAreaAddressChangeText ||
            "the address you entered is outside of onecare connect's covered service area. a member of our customer service team will contact you to complete your request."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showMailingAddressOneCareInCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOcInCountyText ||
            "we have updated your address with caloptima. you need to contact the u.s. social security administration to report your address change."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showPrimaryAddressMediCalOutOfCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileMediCalOutOfCountyText ||
            "thank you for submitting your request to change your address. since you are reporting a change to an out-of-county address, please also contact orange county social services agency at (800) 281-9799 to complete an intercounty transfer request."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showPrimaryAddressOneCareInCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOcInCountyText ||
            "we have updated your address with caloptima. you need to contact the u.s. social security administration to report your address change."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showPrimaryAddressOneCareConnectInCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOccInCountyText ||
            "we have updated your address with caloptima. you need to contact the u.s. social security administration to report your address change."
        }}
      </div>
      <div
        class="alert alert-info"
        *ngIf="showMailingAddressOneCareConnectInCountyMessage"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfileOccInCountyText ||
            "we have updated your address with caloptima. you need to contact the u.s. social security administration to report your address change."
        }}
      </div>
      <div class="alert alert-info" *ngIf="showPhoneNumberAidCodeMessage">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberPofilePhoneNumberInfoAlertAidCodes ||
            "our records indicate your medi-cal eligibility is through the breast and cervical cancer treatment program. please contact the medi-cal eligibility office at 800-824-0088 to report the phone number change."
        }}
      </div>
      <div class="alert alert-info" *ngIf="showPhoneNumberBCCTPMessage">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberPofilePhoneNumberInfoAlertBCCTP ||
            "we have updated your address with caloptima. you need to contact the u.s. social security administration to report your address change."
        }}
      </div>
      <div class="alert alert-info" *ngIf="showPrimaryAddressForwardMessage">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressForwadedToSocialSecurityLabel
        }}
      </div>

      <div class="alert alert-info" *ngIf="showPrimaryPhoneNumberMessage">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
        {{
          updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneNumberSuccessLabel
        }}
      </div>
      <div *ngIf="errorMessage" class="alert alert-danger">
        <i class="fa fa-ban fa-lg" aria-hidden="true"></i>&nbsp;
        <span [innerHTML]="errorMessage"></span>
      </div>
      <div class="alert alert-danger" *ngIf="showTCPASubmissionLimitMessage">
        <i class="fa fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
        <span
          [innerHTML]="
            updateMemberProfileSitecoreTemplate.UpdateMemberProfileTCPAErrorMessage
          "
        ></span>
      </div>
      <div
        *ngIf="successMessage && showSuccessMessage"
        class="alert alert-success"
      >
        <i class="fa fa-check fa-lg" aria-hidden="true"></i> &nbsp;
        {{ successMessage }}
      </div>

      <!-- <div class="row"> -->
      <div class="hdrow-md-12">
        <div class="row">
          <div class="col-md-6 vdcol-md-6">
            <app-address
              name="primaryAddressSection"
              [properties]="primaryAddress.properties"
              [address]="primaryAddress.address"
              (update)="updateMemberPrimaryAddress()"
              (cancel)="clearPrimaryAddressForm()"
              (sSAConsentChanged)="sSAConsentChanged($event)"
            ></app-address>
          </div>

          <div class="col-md-6 vdcol-md-6">
            <app-address
              name="mailingAddressSection"
              [properties]="mailingAddress.properties"
              [address]="mailingAddress.address"
              (update)="updateMemberMailingAddress()"
              (cancel)="clearMailingAddressForm()"
            ></app-address>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <!-- <div class="row"> -->
      <div class="hdrow-md-12">
        <div class="row">
          <div class="col-md-4 vdcol-md-4">
            <app-email
              [viewProperties]="emailViewProperties"
              [validationMessages]="emailValidationMessages"
              emailValue="{{ memberProfile?.Email }}"
              (onEmailUpdated)="updateEmail($event)"
            ></app-email>
          </div>
          <div class="col-md-4 vdcol-md-4">
            <app-phone
              name="memberPrimaryPhone"
              [viewProperties]="phoneViewProperties"
              [validationMessages]="primaryPhoneValidationMessages"
              phoneValue="{{ memberProfile?.HomePhoneNumber }}"
              (onPhoneUpdated)="updateHomePhone($event)"
              (onSSAConsentChecked)="updateHomePhoneSSAConsent($event)"
            >
            </app-phone>
          </div>
          <div class="col-md-4">
            <app-phone
              name="memberMobilePhone"
              [viewProperties]="mobilePhoneViewProperties"
              [validationMessages]="mobilePhoneValidationMessages"
              phoneValue="{{ memberProfile?.MobilePhoneNumber }}"
              (onPhoneUpdated)="updateMobilePhone($event)"
            ></app-phone>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <form>
        <div class="hdrow-md-12">
          <div class="vd-col-spacing">
            <div class="pb-4">
              <h4>
                {{
                  updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPAHeader ||
                    "your Communication Preferences"
                }}
              </h4>
            </div>
            <div>
              <p>
                {{
                  updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPAMessage ||
                    "from time to time, CalOptima will need to contact you with important news about your health care benefits. We are requesting your permission to contact you on your cell phone."
                }}
              </p>

              <div *ngIf="readOnlyTCPA" class="alert alert-info mt-4 mb-4">
                <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>&nbsp;
                <span
                  >{{
                    updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPASameDayUpdateAlert ||
                      "you've already submitted a request to update your communication preferences. Please try again tomorrow."
                  }}
                </span>
              </div>
              <label class="radio-container">
                <input
                  type="radio"
                  [(ngModel)]="TCPAOptIn"
                  name="rdoTCPAOptIn"
                  [disabled]="readOnlyTCPA"
                  id="rdoTCPAOptIn"
                  [value]="true"
                />
                <span>{{
                  updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPAOptInSelection ||
                    "i grant permission for CalOptima to contact my cell phone number for automated calls and text messages.*"
                }}</span>
                <span class="checkmark"></span>
              </label>

              <label class="radio-container">
                <input
                  type="radio"
                  [(ngModel)]="TCPAOptIn"
                  name="rdoTCPAOptOut"
                  [disabled]="readOnlyTCPA"
                  id="rdoTCPAOptOut"
                  [value]="false"
                />
                <span>{{
                  updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPAOptOutSelection ||
                    "i do not grant CalOptima permission to contact my cell phone number."
                }}</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="col-md-12 text-right pb-0 mb-0">
            <button
              class="btn btn-link"
              id="cancelTCPABtn"
              (click)="loadTCPA()"
            >
              {{
                updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText ||
                  "clear"
              }}
            </button>
            <button
              class="btn btn-primary"
              id="updateTCPABtn"
              type="submit"
              (click)="updateTCPA()"
              [disabled]="orgTCPAOptIn === TCPAOptIn"
            >
              {{
                updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText ||
                  "submit"
              }}
            </button>
          </div>
          <div>
            <p class="font-italic small pt-3 pb-0 m-0">
              {{
                updateMemberProfileSitecoreTemplate?.UpdateMemberProfileTCPANote ||
                  "*please note that based on your mobile service plan, message and data rates may be applied by your mobile provider."
              }}
            </p>
          </div>
        </div>
      </form>
      <!-- </div> -->
      <form #preferredLanguagesForm="ngForm">
        <div class="row">
          <div class="col-md-12 vd-col-spacing">
            <div class="col-md-12">
              <h4>
                {{
                  updateMemberProfileSitecoreTemplate.UpdateMemberProfilePreferredLanguagesPanelTitle ||
                    "your language preferences are"
                }}
              </h4>
            </div>
            <div class="col-md-12" *ngIf="!preferredLanguageSupport">
              <p>
                {{
                  updateMemberProfileSitecoreTemplate.UpdateMemberProfilePreferredLanguagesContactHealthNetworkText ||
                    "please contact your health network directly to request materials in your preferred language or format."
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12" id="updateLanguageSection">
          <div class="row">
            <div class="col-md-6 language-format-spacing">
              <label class="control-label">{{
                updateMemberProfileSitecoreTemplate.UpdateMemberProfilePreferredLangaugesWrittenLanguageLabel ||
                  "preferred written language"
              }}</label>
              <select
                [compareWith]="compareLanguage"
                [(ngModel)]="preferredWrittenLanguage"
                (change)="onPreferredWrittenLanguageChange($event.target.value)"
                class="form-select"
                id="preferredWittenLanguageDrp"
                name="preferredWittenLanguage"
              >
                <option selected>---Select---</option>
                <option
                  *ngFor="let language of memberLanguagesList"
                  [ngValue]="language"
                >
                  {{ language.Description }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="control-label">{{
                updateMemberProfileSitecoreTemplate.UpdateMemberProfilePreferredLangaugesSpokenLanguageLabel ||
                  "preferred spoken language"
              }}</label>
              <select
                [compareWith]="compareLanguage"
                [(ngModel)]="preferredSpokenLanguage"
                (change)="onPreferredSpokenLanguageChange($event.target.value)"
                class="form-select"
                id="preferredSpokenLanguageDrp"
                name="preferredSpokenLanguage"
              >
                <option
                  value=""
                  [selected]="
                    !preferredSpokenLanguage.Description ||
                    preferredSpokenLanguage.Description == ''
                  "
                >
                  ---Select---
                </option>
                <option
                  *ngFor="let language of memberLanguagesList"
                  [ngValue]="language"
                >
                  {{ language.Description }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12 text-right">
            <div class="col-md-12">
              <button
                class="btn btn-link"
                id="cancelLanguageBtn"
                (click)="resetLanguagePreferences()"
              >
                {{
                  updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText ||
                    "clear"
                }}
              </button>
              <button
                class="btn btn-primary"
                id="updateLanguageBtn"
                type="submit"
                (click)="updateMemberLanguages()"
                [disabled]="!isLanguagePreferenceChanged"
              >
                {{
                  updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText ||
                    "submit"
                }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
