import { Component, OnInit } from '@angular/core';
import { VirtualVisitsService } from '../shared/services/virtual-visits.service';
import { BHVirtualVisitSitecoreTemplate } from '../shared/bhvirtual-visits-sitecore';

@Component({
  templateUrl: './virtual-visits.component.html',
  styleUrl: './virtual-visits.component.scss',
  providers: [VirtualVisitsService]
})
export class VirtualVisitsComponent implements OnInit {
  public errorMessage: string;
  public content: BHVirtualVisitSitecoreTemplate;
  public isActiveUrl: boolean = true;

  constructor(private virtualVisitsService: VirtualVisitsService) {}

  ngOnInit() {
    this.virtualVisitsService.checkIsActiveUrl()
    .subscribe( {
      next: (response) => {
        this.isActiveUrl = response;
      }
    });
    this.virtualVisitsService.getBHVirtualVisitsSitecoreContent()
    .subscribe( {
      next: (response: BHVirtualVisitSitecoreTemplate) => this.content = response,
      error: (e) => (this.errorMessage = <any>e)
  });
  }

  public getRedirect(): void {
    window.open("/#/bhvirtualvisitsredirect", '_blank');
  }
}
