<p-dialog [(visible)]="showTCPAModalMessage"  [modal]="true" [baseZIndex]="10000" styleClass="modal-dialog-md"
 [draggable]="false" [resizable]="false" [focusOnShow]="true" [closable]="false">
 <p-header>
     {{dashboard?.TCPAHeader || "communication Preferences"}}
 </p-header>
 <div style="margin-left: 15px;margin-right: 15px;" class="text-justify">
    <p> {{dashboard?.TCPAMessage || "from time to time, CalOptima will need to contact you with important news about your health care benefits. We are requesting your permission to contact you on your cell phone."}}</p>

      <label class="radio-container">
        <input type="radio" [(ngModel)]="TCPAOptIn" name="TCPAUpdate" id="rdoTCPAOptIn" [value]="true" />
        <span>{{dashboard?.TCPAOptInSelection || "i grant permission for CalOptima to contact my cell phone number for automated calls and text messages.*"}}</span>
        <span class="checkmark"></span>
    </label>

    <label class="radio-container">
      <input type="radio" [(ngModel)]="TCPAOptIn" name="TCPAUpdate" id="rdoTCPAOptOut" [value]="false" />
      <span>{{dashboard?.TCPAOptOutSelection || "i do not grant CalOptima permission to contact my cell phone number."}}</span>
      <span class="checkmark"></span>
    </label>
    
    <p class="font-italic small pt-3 pb-0 m-0">{{dashboard?.TCPANote || "*please note that based on your mobile service plan, message and data rates may be applied by your mobile provider."}}</p>
 </div>
 <p-footer>
     <button ID="TCPASubmitBtn" (click)="submitCommunication()" class="btn btn-primary" >
     {{dashboard?.TCPASubmitBtn || "submit"}}
     </button>
 </p-footer>
</p-dialog>

<div class="container">
<div class="" id="middle-content">
  <wait-cursor [show]="isLoading"></wait-cursor>
  <error-summary></error-summary>

  <div class="dashboard-container">
    <!-- General Alerts -->
    <div class="{{alertTypeList.get(alert.Type).Class}} alert-dismissable" style="margin-top:15px;margin-bottom:15px;" *ngFor="let alert of generalAlerts">
      <i class="{{alertTypeList.get(alert.Type).Icon}}"></i>&nbsp;
      <strong>{{alert?.Title}}</strong>
      <div style="margin-top:15px;">
        {{alert.Message}}
      </div>
      <br/>
    </div>

    <div *ngIf="dashboard && memberProfile && memberProfile.IsEligible && !disablePersonalRepresentative">
      <!-- AgedOutPRAlerts -->
      <div class="{{alertTypeList.get(alert.Type).Class}} alert-dismissable" style="margin-top:15px;margin-bottom:15px;" *ngFor="let alert of ageOutPRAlerts">
          <i class="{{alertTypeList.get(alert.Type).Icon}}"></i>&nbsp;
          <strong>{{alert?.Title}}</strong>
          <div style="margin-top:15px;">
            {{alert.Message}}
          </div>
          <br/>
      </div>

      <!--<div class="col-md-2" id="leftcol"></div>-->
        <!-- Begin Main Content Area -->
          <div id="caloptima-carousel" class="carousel slide" data-bs-interval="10000" data-bs-ride="carousel" data-bs-cycle="true">
            <!-- Indicators -->
            <!-- <ol class="carousel-indicators">
              <li data-bs-target="#caloptima-carousel" *ngFor="let x of dashboard.CarouselItems; let i = index"
                [attr.data-bs-slide-to]="i" ngClass="i == 0 ? 'active' : ''"></li>
            </ol> -->
            <div class="carousel-indicators">
              <button *ngFor="let x of dashboard.CarouselItems; let i = index"
              type="button" data-bs-target="#caloptima-carousel" [attr.data-bs-slide-to]="i" 
              [class.active]="i === 0" aria-current="i === 0 ? 'true' : 'false'"></button>
            </div>
            <!-- Wrapper for slides -->
            <div class="carousel-inner" style="align-content: center">
              <div *ngFor="let carouselItem of dashboard.CarouselItems; let k = index"
                [ngClass]="k == 0 ? 'carousel-item active' : 'carousel-item'">
                <a id="{{ carouselItem?.ImageSource }}" href="{{ carouselItem?.DestinationUrl }}" 
                target="{{carouselItem?.DestinationUrl.toLowerCase().startsWith('http') ? '_blank' : '_self'}}">
                  <img class="img-fluid center-block" src="{{ carouselItem?.ImageSource }}"
                    alt="{{ carouselItem?.ImageAltText }}" />
                </a>
                <a href="{{ carouselItem?.DestinationUrl }}" target="{{carouselItem?.DestinationUrl.toLowerCase().startsWith('http') ? '_blank' : '_self'}}">
                  <div class="carousel-caption">
                    <h3>{{ carouselItem?.Caption }}</h3>
                  </div>
                </a>
              </div>
            </div>
            <!-- Controls -->
            <a class="carousel-control-prev" href="#caloptima-carousel" role="button" data-bs-slide="prev">
              <span><i class="fa fa-angle-left fa-3x primary" aria-hidden="true"></i></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#caloptima-carousel" role="button" data-bs-slide="next">
              <span><i class="fa fa-angle-right fa-3x primary" aria-hidden="true"></i></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
          <!-- END Carousel -->

        <div class="">
          <!-- Dashboard Buttons -->
          <div class="btn-dashboard-container" id="buttons-content">
            <button id="{{ buttonItem?.Route }}" *ngFor="let buttonItem of dashboardButtons" type="button" class="btn btn-dashboard btn-lg"
              routerLink="{{ buttonItem?.Route }}">
              {{ buttonItem?.Title }}<br />
              <div class="btn-sm btn-body">
                {{ buttonItem?.Body }}
              </div>
            </button>
          </div>
      </div>
    </div>
  </div>
    <div class="my-4 mx-0 px-0 col-md-12" *ngIf="dashboard && memberProfile && memberProfile.IsEligible == false || disablePersonalRepresentative">
      <p *ngIf="!disablePersonalRepresentative" >
      {{
        dashboard.ViewOnlyMemberWelcomeText ||
          "our records indicate that you are no longer eligible for benefits through caloptima. until your eligibility is reinstated you will be able to view your account up to 60 days, but will not be able to use any of the online service tools on this site. if you have any questions please call caloptima's customer service department."
      }}
      </p>
      <h1 class="view-only-personal-representative" *ngIf="disablePersonalRepresentative" >
        <div>
          {{
            dashboard.ViewOnlyPersonalRepresentativeWelcomeHeaderText ||
            "your access request is being reviewed by calOptima."
          }}
        </div>
        <small>
          {{
            dashboard.ViewOnlyPersonalRepresentativeWelcomeBodyText ||
            "please allow 3-5 business days to get full access to member portal."
          }}
        </small>
      </h1>
    </div>
  <!--<div *ngIf="dashboard && memberProfile && memberProfile.IsEligible">-->
  <div class="row" *ngIf="dashboard && memberProfile && memberProfile.IsEligible && !disablePersonalRepresentative">
    <div class="col-md-12" id="cta-content">
      <div class="calltoaction calltoaction-primary">
        <div class="row">
          <div class="col-lg-9 col-md-8 col-sm-12 cta-contents">
            <h1 class="cta-title">
              {{
                dashboard?.CallToAction?.Title || 'interested in your health?'
              }}
            </h1>
            <div class="cta-desc">
              <p>
                {{
                  dashboard?.CallToAction?.Body ||
                    'explore our health and wellness library for self-care guides and brochures about quitting smoking, asthma, diabetes, nutrition, exercise, and more!'
                }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 cta-button">
            <a href="{{ dashboard?.CallToAction?.ButtonUrl }}" rel="noopener noreferrer" target="_blank"
              class="btn btn-lg btn-cta">
              {{ dashboard?.CallToAction?.ButtonTitle || 'get started' }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- Member Messages -->
<app-member-message *ngIf="memberProfile && memberProfile.IsEligible && !disablePersonalRepresentative"></app-member-message>
</div>
</div>
