import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { MessageRequest } from '../shared/message-request';
import { MessageResponse } from '../shared/message-response';
import { MessageSitecoreTemplate } from '../shared/message-sitecore-template';
import { MessageService } from '../shared/services/message-service';

@Component({
  selector: 'app-member-message',
  providers:[MessageService],
  templateUrl: './member-message.component.html',
  styleUrls: ['./member-message.component.scss']
})
export class MemberMessageComponent implements OnInit, OnDestroy{
  private subscription: Subscription;
  memberMessages: MessageResponse[] = new Array();
  messageTemplate: MessageSitecoreTemplate = new MessageSitecoreTemplate();
  expandIndex: number = -1;

  constructor(private languageService: LanguageService, private service: MessageService) { }

  ngOnInit(): void {
    try {

      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.onLoad();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private onLoad()
  {
    this.service.getMemberMessageSitecoreContent().subscribe(response => {
      this.messageTemplate = response;
    });
    
    this.service.getMemberMessages().subscribe( response => {
      this.memberMessages = response;
    });
  }
  public onMessageExpand(i: number)
  {
    if(this.expandIndex == i) 
    {
      this.expandIndex = -1;
      return;
    }
    else 
    {
      this.expandIndex = i;
      if(this.memberMessages && this.memberMessages[i] && !this.memberMessages[i].MessageStatus.HasRead){
        this.memberMessages[i].MessageStatus.HasRead = true;
        let request = new MessageRequest();
        request.HasDelete = this.memberMessages[i].MessageStatus.HasDelete;
        request.HasRead = this.memberMessages[i].MessageStatus.HasRead;
        request.MessageCode = this.memberMessages[i].MessageModel.MessageCode;
        request.MessageID = this.memberMessages[i].MessageStatus.MessageID;
        request.MessageStatusID = this.memberMessages[i].MessageStatus.MessageStatusID;
        
        this.service.updateMemberMessage(request).subscribe(res =>{
          if(res > 0)
          {
            this.memberMessages[i].MessageStatus.MessageStatusID = res
          }
        });
      }
    }
  }
  public onMessageDelete(i: number)
  {
      if(this.memberMessages && this.memberMessages[i] && !this.memberMessages[i].MessageStatus.HasDelete){
        this.memberMessages[i].MessageStatus.HasDelete = true;
        let request = new MessageRequest();
        request.HasDelete = this.memberMessages[i].MessageStatus.HasDelete;
        request.HasRead = this.memberMessages[i].MessageStatus.HasRead;
        request.MessageCode = this.memberMessages[i].MessageModel.MessageCode;
        request.MessageID = this.memberMessages[i].MessageStatus.MessageID;
        request.MessageStatusID = this.memberMessages[i].MessageStatus.MessageStatusID;

        this.service.updateMemberMessage(request).subscribe(res=>{
          this.memberMessages = this.memberMessages.filter(obj => obj !== this.memberMessages[i]);
        });
      }
  }
  
  public getType(type: string)
  {
    type = type.toLowerCase();
    switch(type){
      case 'success': return 'msg-success';
      case 'warning': return 'msg-warning';
      default: return 'msg-info';
    }
  }
}
